import React from "react"

import { Layout, Seo } from "../components"

const Contact = () => (
  <Layout>
    <Seo title="Contact Us" />
    <section className="section has-background-white">
      <div className="container content">
        <h1>Contact Us</h1>
        <p>This is exciting stuff! We're glad you want to get in touch. We have a few ways to do that below.</p>
        <h2>You can use this form...</h2>
        <form name="contact" method="post" netlify-honeypot="bot-field" data-netlify="true">
        <input type="hidden" name="bot-field" />
          <div className="field">
            <label className="label" htmlFor="name">Name</label>
            <div className="control">
              <input className="input" type="text" name="name" id="name" />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="email">Email</label>
            <div className="control">
              <input className="input" type="email" name="email" id="email" />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="message">Your Message</label>
            <div className="control">
              <textarea className="textarea" name="message" id="message" />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button type="submit" className="button is-link">Submit</button>
            </div>
          </div>
        </form>
        <h2>Or you can email us directly...</h2>
        <p>Send Mark an email at <a href="mailto:markashmorefrsa@gmail.com">markashmorefrsa@gmail.com</a></p>
      </div>
    </section>
  </Layout>
)

export default Contact
